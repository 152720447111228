import { baseURL, s3BaseURL } from "../../../../../etc/settings"
import {
	IFeaturedImage,
	IGroupSalesTeam,
	IJuniorGrizzliesCampaignRaw,
	IPageWrapper
} from "../../../../../etc/types"

export const assetsBaseURL = s3BaseURL + "assets/custom_pages/junior-grizzlies/"
export const imageAssetsURL = assetsBaseURL + "img/"
export const videoAssetsURL = assetsBaseURL + "video/"

export const videoLink = videoAssetsURL + "JuniorGrizzlies_SummerCamps_Promo.mp4"
export const posterLink =
	imageAssetsURL + "MG_240627_JuniorGrizzlies_SummerCamps_NoCTA_1920x1080.jpg"

const staticData: IPageWrapper = {
	title: "Junior Grizzlies",
	description:
		"Junior Grizzlies is the official youth basketball program of the Memphis Grizzlies and is geared toward boys and girls, of all skill levels, ages 6-14. Our mission is to create lifelong memories through basketball by providing a fun, educational, and safe environment for all.",
	pageImage: imageAssetsURL + "MG_240627_JuniorGrizzlies_SummerCamps_NoCTA_1920x1080.jpg",
	pageLink: baseURL + "junior-grizzlies"
}

export default staticData

export const featuredImageData: IFeaturedImage = {
	title: staticData.title,
	description: staticData.description,
	src: staticData.pageImage,
	actionButton: {
		title: "Connect with us",
		href:
			"https://memphisbasketball.formstack.com/forms/memphis_grizzlies_youth_basketball_league_contact_us",
		target: "_blank"
	}
}

export const ourMissionMeta = `
Junior Grizzlies is the official youth basketball program of the Memphis Grizzlies and is geared toward boys and girls, of all skill levels, ages 6-14. Our mission is to create lifelong memories through basketball by providing a fun, educational, and safe environment for all.
`.split("\n")

export const campsDescription = `
Our camps, which are designed for boys and girls ages 6-14 of all skill levels, focus on basketball fundamentals, drills, and fun competitive games.
`.split("\n")

export const clinicsDescription = `
Our clinics, which are also designed for boys and girls ages 6-14 of all skill levels, are a condensed version of what to expect at our camps.
`.split("\n")

export const campsAndClinicsNote = `
All skill levels are welcome as campers will be grouped by age and ability.
(Experienced 6-year olds may also attend.)
Game tickets will be offered to select games with redemption information provided during the camp. Final information (such as what to wear, where to enter, etc.) will be e-mailed to registrants the Thursday prior to each camp.
All Grizz Camp & Clinic sales are final.
`.split("\n")

export const faqList: any = `What does a typical day at camp look like?
Junior Grizzlies focuses on offensive and defensive fundamentals through stations and drills, but we also make it a point to play competitive games.
Our priorities are teaching and making sure every child has fun.

Where are camps and clinics located?
Camps and clinics are held at various facilities in Memphis, its suburbs, as well as in cities within a 3-hour radius of Memphis.

What is the age range of camps and clinics?
Camps are available for children age 6-14.

My child has never played basketball before. Can they still attend a camp or clinic?
Yes. Since fundamentals are a key teaching emphasis at our camps and clinics, Junior Grizzlies is ideal for both beginner and intermediate athletes.

What is the refund policy?
Refunds are handled on a case-by-case basis as the policy varies by program.
Please contact us with questions regarding refunds.

Can parents stay and watch?
Yes! Parents are welcome to stay for as much or little time as they want, but it certainly is not a requirement.

Who are the coaches at your camps and clinics?
Our coaching staff is made up of experienced individuals who come from a background of playing or coaching at higher levels.

Are tickets included with camps and clinics?
Some camps and clinics do include tickets to Grizzlies games.
If tickets are included, it will be clearly noted during the registration process.
`
	.split("\n\n")
	.map((value) => value.split("\n"))

export const team: IGroupSalesTeam[] = [
	{
		name: "Corey Elliott",
		title: "Manager<span></span> Junior Grizzlies",
		email: "celliott@grizzlies.com",
		photo: "corey-elliott.jpg"
	},
	{
		name: "Ronni Williams",
		title: "Coordinator<span></span> Junior Grizzlies",
		email: "rwilliams@grizzlies.com",
		photo: "ronni-williams-4.jpg"
	}
]

export const campaigns: {
	camps: IJuniorGrizzliesCampaignRaw[]
	clinics: IJuniorGrizzliesCampaignRaw[]
	other: IJuniorGrizzliesCampaignRaw[]
} = {
	// EVENTS INFO
	// There's a cutoff date at the end of each string: y.m.d WITH LEADING ZEROS. Set price to "SOLD OUT" for camp to set appearance for sold out events.
	// Structure: date & times / location name / address / price / additional info / direct url / cutoff date
	// Example: "June 26-27, 9 AM to 3 PM###Northwest Family YMCA**###3700 Ashland City Hwy, Nashville, TN 37218###239###The camp will feature an appearance by John Konchar, subject to change.**###https://grizzlies.leagueapps.com/events/4381593-memphis-grizzlies-vs.-portland-trail-blazers###24.09.11",
	camps: [
		{
			title: "4-day Summer Camps",
			description:
				"Boys and girls, ages 6-14, can take their game to the next level with Junior Grizzlies Summer Camps, presented by OrthoSouth. Each camper will receive expert basketball instruction, a Grizzlies reversible jersey, a Grizzlies basketball, a beach towel, and Junior Grizzlies socks. All campers must bring their own lunch, snacks, and water.",
			events: [
				"June 2-5, 9 AM to 3 PM###Independent Presbyterian Church### 4738 Walnut Grove Rd, Memphis, TN 38117### 289 #########25.08.01",
				"June 9-12, 9 AM to 3 PM### Shoot 360 Cordova ### 1375 Big Orange Rd, Cordova, TN 38018 ### 339 #########25.08.01",
				"June 16-19, 9 AM to 3 PM### Bartlett YMCA ### 7700 Flaherty Pl, Memphis, TN 38133 ### 289 #########25.08.01",
				"June 23-26, 9 AM to 3 PM### Houston Middle School  ### 9400 Wolf River Blvd, Germantown, TN 38139 ### SOLD OUT #########25.08.01",
				"July 7-10, 9 AM to 3 PM### Gatorade Fieldhouse ###  Green T Rd E, Hernando, MS 38632  ### 289 #########25.08.01",
				"July 7-10, 9 AM to 3 PM###  Itawamba Community College ###  602 West Hill Street Fulton, MS 38843  ### 289 #########25.08.01",
				"July 14-17, 9 AM to 3 PM###  Memphis Sports and Events Center (All Girls) ### 995 Early Maxwell Blvd, Memphis, TN 38104 ### 289 #########25.08.01",
				"July 21-24, 9 AM to 3 PM### Shoot 360 Cordova  ###  375 Big Orange Rd, Cordova, TN 38018 ### 339 #########25.08.01",
				"July 21-24, 9 AM to 3 PM### Red Wolf Center (Arkansas State University) ###  2501 Aggie Rd, Jonesboro, AR 72401 ### 289 #########25.08.01",
				// "July 21-24, 9 AM to 3 PM### Knowledge Academies ###  5320 Hickory Hollow Pkwy, Antioch, TN 37013 ### 289 #########25.08.01",
				"July 28-31, 9 AM to 3 PM### Penick Boys & Girls Club ###  1201 Leisure Pl, Little Rock, AR 72204 ### 289 #########25.08.01"
			],
			url: "https://grizzlies.leagueapps.com/events/4490563-junior-grizzlies-summer-camps"
		}
	],
	clinics: [
		// {
		// 	title: "Hustle Gameday Clinics",
		// 	description: "",
		// 	events: [
		// 		// There's a cutoff date at the end of each string: y.m.d WITH LEADING ZEROS.
		// 		// Example: "June 26-27, 9 AM to 3 PM###Northwest Family YMCA**###3700 Ashland City Hwy, Nashville, TN 37218###239###The camp will feature an appearance by John Konchar, subject to change.**###24.09.11",
		// 	],
		// 	url: "https://memphisbasketball.formstack.com/forms/2024_youth__basketball_summer_camps"
		// }
		{
			title: "Grizzlies Gameday Clinics",
			description:
				"Junior Grizzlies, presented by OrthoSouth, is excited to offer a unique clinic experience for five Memphis Grizzlies home games during the 2024/25 season. Boys and girls, ages 6-14, will have the opportunity to participate in a clinic on the court at FedExForum the day of a Grizzlies game. Participants will receive a backpack, t-shirt, and two Big River Steel Terrace IV tickets to the Memphis Grizzlies game taking place the day of the clinic. Participants (and guests) and can also optionally attend pregame shoot around prior to the game that is included.",
			events: [
				"November 25, 1 PM to 2 PM & 2 PM to 3 PM###Grizzlies vs. Trail Blazers###191 Beale St, Memphis, TN 38103###150 + Transaction Fees###Session 1 (ages 6-9): 1 PM - 2PM, Session 2 (ages 10-14): 2 PM - 3 PM ###https://grizzlies.leagueapps.com/events/4381593-memphis-grizzlies-vs.-portland-trail-blazers###24.11.25",
				"December 23, 1 PM to 2 PM & 2 PM to 3 PM###Grizzlies vs. Clippers###191 Beale St, Memphis, TN 38103###150 + Transaction Fees###Session 1 (ages 6-9): 1 PM - 2PM, Session 2 (ages 10-14): 2 PM - 3 PM ###https://grizzlies.leagueapps.com/events/4385755-memphis-grizzlies-vs.-los-angeles-clippers###24.12.23",
				"February 8, 1 PM to 2 PM & 2 PM to 3 PM###Grizzlies vs. Thunder###191 Beale St, Memphis, TN 38103###150 + Transaction Fees###Session 1 (ages 6-9): 1 PM - 2PM, Session 2 (ages 10-14): 2 PM - 3 PM ###https://grizzlies.leagueapps.com/events/4385759-memphis-grizzlies-vs.-oklahoma-city-thunder###25.02.08",
				"March 10, 1 PM to 2 PM & 2 PM to 3 PM###Grizzlies vs. Suns###191 Beale St, Memphis, TN 38103###150 + Transaction Fees###Session 1 (ages 6-9): 1 PM - 2PM, Session 2 (ages 10-14): 2 PM - 3 PM ###https://grizzlies.leagueapps.com/events/4385767-memphis-grizzlies-vs.-phoenix-suns###25.03.10"
				// CANCELLED: "March 12, 1 PM to 2 PM & 2 PM to 3 PM###Grizzlies vs. Jazz (All Girls)###191 Beale St, Memphis, TN 38103###150 + Transaction Fees###Session 1 (ages 6-9): 1 PM - 2PM, Session 2 (ages 10-14): 2 PM - 3 PM ###https://grizzlies.leagueapps.com/events/4385768-memphis-grizzlies-vs.-utah-jazz-all-girls-clinic###25.03.12"
			],
			url: "https://grizzlies.leagueapps.com/events/4381575-junior-grizzlies-game-day-clinics"
		}
		// {
		// 	title: "LeadHership Clinic",
		// 	description:
		// 		"The Grizzlies Youth Basketball staff will host an all-girls LeadHERship Clinic at the MAM Center from 9 AM to 12 PM for ages 10-14. The event will include workshops on mental health, vision boards, and a panel discussion with special guests. Afterward, participants will engage in basketball drills and games. Each participant will receive a WNBA basketball, an exclusive Grizzlies LeadHERship t-shirt, a vision board, and a Terrace IV ticket to the 2/28 Grizzlies vs. Knicks game.",
		// 	events: [
		// 		// There's a cutoff date at the end of each string: y.m.d WITH LEADING ZEROS.
		// 		// Example: "June 26-27, 9 AM to 3 PM###Northwest Family YMCA**###3700 Ashland City Hwy, Nashville, TN 37218###239###The camp will feature an appearance by John Konchar, subject to change.**###https://grizzlies.leagueapps.com/events/4381593-memphis-grizzlies-vs.-portland-trail-blazers###24.09.11",
		// 		"February 22, 9 AM to 12 PM ###LeadHership Clinic - MAM Center ###2107 Ball Rd, Memphis, TN 38114###60 + Transaction Fees###Ages 10-14###https://grizzlies.leagueapps.com/events/4472941-leadhership-clinic",
		// 	],
		// 	url: "https://grizzlies.leagueapps.com/events/4381575-junior-grizzlies-game-day-clinics"
		// }
	],
	other: [
		{
			title: "",
			description: "",
			events: [
				"December 15, 2024​ 11 AM - 2 PM###Memphis Hustle Coaching Clinic###4560 Venture Dr, Southaven, MS 38671###FREE###The Memphis Hustle and Junior Grizzlies are offering a FREE youth basketball coaching clinic on Sunday, December 15 at Landers Center, presented by OrthoSouth. The clinic will feature an exclusive sneak peek into the Hustle's practice, on-court presentations from Hustle coaching staff where participant coaches will be educated on various subjects including Skill Development Training, X’s and O’s, and Coaching Best Practices. Attendees will also hear from Positive Coaching Alliance. Coaches at all levels are encouraged to attend. See the schedule below.###https://grizzlies.leagueapps.com/events/4411420-junior-grizzlies-x-memphis-hustle-coaching-clinic###24.12.15",
				"January 4, 2025​ 9 AM - 4 PM###3X3 TOURNAMENT###191 Beale St, Memphis, TN 38103###100/team###Teams of 3-5 players will have the opportunity to play at least three games at FedExForum in the Memphis Grizzlies' Practice Facility. There will be boys and girls divisions that consist of 10U, 12U, and 14U. Each player will receive a Junior Grizzlies 3x3 jersey and the chance to win tickets and more.###https://www.hoopitup.com/grizzlies###25.01.04"
			],
			url: ""
		}
	]
}
